try {
  window.Popper = require('popper.js').default;
  $ = global.jQuery = window.$ = window.jQuery = require('jquery');
  global.moment = require('moment');
  require('bootstrap');
  require('bootstrap-autocomplete');
  require('bootstrap4-tagsinput/tagsinput.js');
  require('tempusdominus-bootstrap-4');
  require('infinite-scroll/dist/infinite-scroll.pkgd.js');
  require('./additional.js');
} catch (e) {}

$(document).ready(function () {
  "use strict"; // start of use strict

  var locationHash = location.hash.replace(/^#/, '');
  if(locationHash == 'signUp') {
    $('#signupModal').modal('show');
  }
  if(locationHash == 'login') {
    $('#loginModal').modal('show');
  }
  if(locationHash == 'invoiceModal') {
    $('#invoiceModal').modal('show');
  }

  $('.carousel').carousel({
    interval: 10000
  })

  $(".toggle-password").click(function() {
    $(this).toggleClass("fa-eye fa-eye-slash");
    var input = $($(this).data("toggle"));
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });

  $('.modal').on('show.bs.modal', function (e) {
    $('.invalid-feedback').children('strong').text('');
    $('input').removeClass('is-invalid');
  })

  $('#loginModal').on('shown.bs.modal', function (e) {
    $('#navLoginButton').one('focus', function (e) {
      $(this).blur();
    });
  });

  $('#loginForm').submit(function (e) {
    e.preventDefault();
    let formData = $(this).serializeArray();
    $('.invalid-feedback').children('strong').text('');
    $('input').removeClass('is-invalid');
    $.ajax({
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      url: '/users/login',
      data: formData,
      success: () => {
        if ($('#redirectLogin').val() != '') {
          document.location.href = $('#redirectLogin').val();
        } else {
          if(locationHash == 'login') {
            document.location.href = '/';
          } else {
            window.location.reload();
          }
        }
      },
      error: (response) => {
        if(response.status === 422) {
          $('#loginEmail').addClass('is-invalid');
          $('#loginPassword').addClass('is-invalid');
          $('#loginError').text(response.responseJSON.error);
        }
        else if(response.status === 302) {
          window.location.href = '/admin';
        }
        else {
          window.location.reload();
        }
      }
    })
  });

  $('#signupForm').submit(function (e) {
    e.preventDefault();
    let formData = $(this).serializeArray();
    $('.invalid-feedback').children('strong').text('');
    $('input').removeClass('is-invalid');
    $.ajax({
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      url: '/users/signup',
      data: formData,
      success: () => {
        window.location.assign('/users/signedup')
      },
      error: (response) => {
        console.log(response);
        if(response.status === 422) {
          let errors = response.responseJSON.errors;
          Object.keys(errors).forEach(function (key) {
            console.log(key);
            $('#' + key + 'Signup').addClass('is-invalid');
            $('#' + key + 'SignupError').children('strong').text(errors[key]);
          });
        } else {
          window.location.reload();
        }
      }
    })
  });

  $('#passwordResetForm').submit(function (e) {
    e.preventDefault();
    let formData = $(this).serializeArray();
    $.ajax({
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      url: '/users/forgotPasswordSend',
      data: formData,
      success: () => {
        document.location.href = '/users/forgotPassword';
      },
      error: (response) => {
        window.location.reload();
      }
    })
  });

  $('.home-autocomplete').autoComplete({
    resolver: 'custom',
    events: {
      search: function (qry, callback) {
        $.ajax(
          '/services/home-autocomplete',
          {
            data: { 'qry': qry}
          }
        ).done(function (res) {
          callback(res.results);
        });
      }
    }
  });

  $('.services-autocomplete').autoComplete({
    resolver: 'custom',
    events: {
      search: function (qry, callback) {
        $.ajax(
          '/services/autocomplete',
          {
            data: { 'qry': qry}
          }
        ).done(function (res) {
          callback(res.results);
        });
      }
    }
  });

  $('#serviceFormSkills').tagsinput({
    maxTags: 5,
    maxChars: 30,
    trimValue: true,
  });

  $('#taskFormSkills').tagsinput({
    maxTags: 5,
    maxChars: 30,
    trimValue: true,
  });

  $('#costPer').change(function() {
    if ($('#costPer').val() == 'Other') {
      $('#costPerOther').show();
    } else {
      $('#costPerOther').hide();
    }
  });

  $('.tasks-autocomplete').autoComplete({
    resolver: 'custom',
    events: {
      search: function (qry, callback) {
        $.ajax(
          '/tasks/autocomplete',
          {
            data: { 'qry': qry}
          }
        ).done(function (res) {
          callback(res.results);
        });
      }
    }
  });

  $('.cities-autocomplete').autoComplete({
    resolver: 'custom',
    events: {
      search: function (qry, callback) {
        $.ajax(
          '/cities/autocomplete',
          {
            data: { 'qry': qry}
          }
        ).done(function (res) {
          callback(res.results);
        });
      }
    }
  });

  $('#searchTop').submit(function (e) {
    e.preventDefault();
    var search = $('input[name="search"]').val();
    if (search.match(/^[0-9]+$/)) {
      window.location.href = '/services?subcategory=' + search;
    } else {
      window.location.href = '/services?skill=' + search;
    }
  });

  $('#searchCity').change(function() {
    $('#searchRemote').prop("checked", false);
  });

  $('#directPayment').click(function (e) {
    $.ajax({
      method: 'GET',
      headers: {
        Accept: 'application/json'
      },
      url: '/users/changePaymentType/1',
      success: () => {
        window.location.href = '/myprofile';
        window.location.reload(true);
      },
      error: () => {
        console.log(data);
      }
    })

  });

  $('#searchRemote').click(function() {
    $('#searchCity').val('');
    $('input[name="city"]').val('');
  });

  $('.my-service-link').click(function(e) {
    $('.my-service-link').removeClass('active');
    $(this).addClass('active');
    if ($(window).width() < 992) {
      window.location.href = '/services/details/' + $(this).data('service-id');
    } else {
      $('#serviceDetails').load('/services/details/' + $(this).data('service-id') + '?load=1');
    }
  });

  if ($('#serviceList').length) {
    var hash = location.hash.replace(/^#/, '');
    if (hash || $(window).width() >= 992) {
      if (!hash || !$('#serviceLink' + hash).length) {
        hash = $('.my-service-link.service-active').first().data('service-id');
      }
      $('#serviceLink' + hash).addClass('active');
      if ($(window).width() < 992) {
        window.location.href = '/services/details/' + hash;
      } else {
        $('#serviceDetails').load('/services/details/' + hash + '?load=1');
      }
    }
  };

  if ($('#taskList').length) {
    var hash = location.hash.replace(/^#/, '');
    if (hash || $(window).width() >= 992) {
      if (!hash || !$('#taskLink' + hash).length) {
        hash = $('.my-task-link.task-active').first().data('task-id');
      }
      $('#taskLink' + hash).addClass('active');
      if ($(window).width() < 992) {
        window.location.href = '/tasks/details/' + hash;
      } else {
        $('#taskDetails').load('/tasks/details/' + hash + '?load=1');
      }
    }
  };

  if ($('#orderList').length) {
    var hash = location.hash.replace(/^#/, '');
    if (hash || $(window).width() >= 992) {
      if (!hash || !$('#orderLink' + hash).length) {
        hash = $('.my-order-link.order-active').first().data('order-id');
      }
      $('#orderLink' + hash).addClass('active');
      if ($(window).width() < 992) {
        window.location.href = '/orders/details/' + hash;
      } else {
        $('#orderDetails').load('/orders/details/' + hash + '?load=1');
      }
    }
  };

  $('#showServicesInactive').change(function() {
    if ($('#showServicesInactive').prop('checked')) {
      $('.my-service-link.service-inactive').removeClass('d-none');
      $('.my-service-link.service-inactive').addClass('d-block');
    } else {
      $('.my-service-link.service-inactive').removeClass('d-block');
      $('.my-service-link.service-inactive').removeClass('active');
      $('.my-service-link.service-inactive').addClass('d-none');
      var activateFirst = true;
      $('.my-service-link.service-active').each(function() {
        if ($(this).hasClass('active')) {
          activateFirst = false;
        }
      });
      if (activateFirst) {
        $('.my-service-link.service-active').first().toggleClass('active', true);
        $('#serviceDetails').load('/services/details/' + $('.my-service-link.service-active').first().data('service-id') + '?load=1');
      }
    }
  });

  $(document).on('show.bs.modal', '#serviceStatusModal', function(e){
    var link = $(e.relatedTarget);
    $('#serviceStatusLabel').html(link.data('label'));
    $('#serviceStatusMessage').html(link.data('message'));
    $('#serviceStatusValue').val(link.data('value'));
    $('#serviceStatusButton').val(link.data('button'));
  });

  $(document).on('show.bs.modal', '#orderStatusModal', function(e){
    var link = $(e.relatedTarget);
    $('#orderStatusLabel').html(link.data('label'));
    $('#orderStatusMessage').html(link.data('message'));
    $('#orderStatusId').val(link.data('order-id'));
    $('#orderStatusValue').val(link.data('value'));
    $('#orderStatusButton').val(link.data('button'));
  });

  $(document).on('show.bs.modal', '#offerStatusModal', function(e){
    var link = $(e.relatedTarget);
    $('#offerStatusLabel').html(link.data('label'));
    $('#offerStatusMessage').html(link.data('message'));
    $('#offerStatusId').val(link.data('offer-id'));
    $('#offerStatusValue').val(link.data('value'));
    $('#offerStatusButton').val(link.data('button'));
  });

  $(document).on('submit', '#orderStatusForm', function (e) {
    e.preventDefault();
    var formData = $(this).serializeArray();
    $.ajax({
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      url: '/orders/status',
      data: formData,
      success: (data) => {
        console.log(data)
        $('#orderStatusModal').modal('hide');
        window.location.href = '/orders/list#' + data.orderId;
        window.location.reload(true);
      },
      error: (data) => {
        //console.log(data);
        $('#orderStatusMessage').html('<span class="text-danger">Sorry, this order has been cancelled due to payment processing issues.<br/><br/>MicroTasks Admin has been notified of this error.</span>');
        $('#orderStatusButton').prop('disabled', true);
      }
    })
  });

  $(document).on('submit', '#offerStatusForm', function (e) {
    e.preventDefault();
    var formData = $(this).serializeArray();
    $.ajax({
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      url: '/offers/status',
      data: formData,
      success: (data) => {
        $('#offerStatusModal').modal('hide');
        if ($(window).width() < 992) {
          window.location.href = '/services/details/' + data.serviceId + '?tab=offers';
        } else {
          $('#serviceDetails').load('/services/details/' + data.serviceId + '?load=1&tab=offers');
        }
      },
      error: (data) => {
        console.log(data);
      }
    })
  });

  $.fn.datetimepicker.Constructor.Default = $.extend({},
    $.fn.datetimepicker.Constructor.Default,
      { icons:
        { time: 'fas fa-clock',
          date: 'fas fa-calendar',
          up: 'fas fa-arrow-up',
          down: 'fas fa-arrow-down',
          previous: 'fas fa-arrow-circle-left',
          next: 'fas fa-arrow-circle-right',
          today: 'far fa-calendar-check-o',
          clear: 'fas fa-trash',
          close: 'fas fa-times' }
      }
  );

  if ($('#orderStartDateTime').length > 0) {
    var startDefaultDate = '';
    if ($('#orderStartDateTime').data('default-text')) {
      startDefaultDate = new Date($('#orderStartDateTime').data('default-text'));
    }
  }

  if ($('#orderEndDateTime').length > 0) {
    var endDefaultDate = '';
    if ($('#orderEndDateTime').data('default-text')) {
      endDefaultDate = new Date($('#orderEndDateTime').data('default-text'));
    }
  }

  $('#orderStartDateTime').datetimepicker({
    useCurrent: false,
    minDate: moment().startOf('day'),
    defaultDate: startDefaultDate,
    format: 'L',
    buttons: { showClose: true},
  });

  $('#orderEndDateTime').datetimepicker({
    useCurrent: false,
    minDate: moment().startOf('day'),
    defaultDate: endDefaultDate,
    format: 'L',
    buttons: { showClose: true},
  });

  $("#orderStartDateTime").on("change.datetimepicker", function (e) {
    $('#orderEndDateTime').datetimepicker('minDate', e.date);
  });

  $("#orderEndDateTime").on("change.datetimepicker", function (e) {
    $('#orderStartDateTime').datetimepicker('maxDate', e.date);
  });

  $('.pagination-container').infiniteScroll({
    path: '.pagination-next',
    append: '.pagination-item',
    hideNav: '.pagination',
    button: '.view-more-button',
    status: '.pagination-status',
    scrollThreshold: false,
    history: true,
  });

  $('#agreedSignup'). click(function(){
    if($(this). prop("checked") == true){
      $('#agreedSignupError').hide();
      }
    else{
      $('#agreedSignupError').show();
    }
    });

});
