$(document).ready( function () {
  "use strict"; // start of use strict

  /* My Tasks specific script */

  $('.my-task-link').click( function(e) {
    $('.my-task-link').removeClass('active');
    $(this).addClass('active');
    if ($(window).width() < 992) {
      window.location.href = '/tasks/details/' + $(this).data('task-id');
    } else {
      $('#taskDetails').load('/tasks/details/' + $(this).data('task-id') + '?load=1');
    }
  });

  $('#showTasksInactive').change( function() {
    if ($('#showTasksInactive').prop('checked')) {
      $('.my-task-link.task-inactive').removeClass('d-none');
      $('.my-task-link.task-inactive').addClass('d-block');
    } else {
      $('.my-task-link.task-inactive').removeClass('d-block');
      $('.my-task-link.task-inactive').removeClass('active');
      $('.my-task-link.task-inactive').addClass('d-none');
      var activateFirst = true;
      $('.my-task-link.task-active').each(function() {
        if ($(this).hasClass('active')) {
          activateFirst = false;
        }
      });
      if (activateFirst) {
        $('.my-task-link.task-active').first().toggleClass('active', true);
        $('#taskDetails').load('/tasks/details/' + $('.my-task-link.task-active').first().data('task-id'));
      }
    }
  });

  $('#showTasksCompleted').change( function() {
    if ($('#showTasksCompleted').prop('checked')) {
      $('.my-task-link.task-completed').removeClass('d-none');
      $('.my-task-link.task-completed').addClass('d-block');
    } else {
      $('.my-task-link.task-completed').removeClass('d-block');
      $('.my-task-link.task-completed').removeClass('active');
      $('.my-task-link.task-completed').addClass('d-none');
      var activateFirst = true;
      $('.my-task-link.task-active').each(function() {
        if ($(this).hasClass('active')) {
          activateFirst = false;
        }
      });
      if (activateFirst) {
        $('.my-task-link.task-active').first().toggleClass('active', true);
        $('#taskDetails').load('/tasks/details/' + $('.my-task-link.task-active').first().data('task-id'));
      }
    }
  });

  /* My Orders specific script */


  $('.my-order-link').click( function(e) {
    $('.my-order-link').removeClass('active');
    $(this).addClass('active');
    if ($(window).width() < 992) {
      window.location.href = '/orders/details/' + $(this).data('order-id');
    } else {
      $('#orderDetails').load('/orders/details/' + $(this).data('order-id') + '?load=1');
    }
  });

  $('#myFileSelector').on('change', function (e) {
    if (this.files && this.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        $('#preview-avatar').attr('src', e.target.result);
        $('#preview-avatar').width('140px');
        $('#preview-avatar').height('106px');

        $('#progBar').width('20%');
        $('#progBar').text('Ready');
      };
      reader.readAsDataURL(this.files[0]);
    }
  });

  $('#update').on('click', function (e) {
    $('#progBar').width('100%');
    $('#progBar').text('Uploading...');
  });

  $('#uploadForm').submit(function (e) {
    e.preventDefault();
    $('#progBar').width('100%');
    $('#progBar').text('Done');
    let formData = $(this).serializeArray();
    $.ajax({
      method: 'POST',
      headers: {
        Accept: 'application/json',
        contentType: false,
        processData: false,
        cache : false,
      },
      url: '/users/upload',
      data: formData,
      success: () => window.location.reload(),
    });
  });

  $('#afterPost').hide();

  $(document).on('submit', '#questionForm', function (e) {
    e.preventDefault();
    var formData = $(this).serializeArray();
    $.ajax({
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      url: '/services/addQuestion',
      data: formData,
      success: () => {
        $('#questionForm').hide();
        $('#afterPost').show();
      }
    })
  });

  $(document).on('submit', '#answerForm', function (e) {
    e.preventDefault();
    var formData = $(this).serializeArray();
    $.ajax({
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      url: '/services/addAnswer',
      data: formData,
      success: () => {
        window.location.reload()
      }
    })
  });

  $(document).on('submit', '#reviewForm', function (e) {
    e.preventDefault();
    var formData = $(this).serializeArray();
    $.ajax({
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      url: '/orders/addReview',
      data: formData,
      success: () => window.location.reload(),
    });
  });

  $(document).on('show.bs.modal', '#answerModal', function(e) {
    var link = $(e.relatedTarget);
    $('#consumer_id').val(link.data('consumerid'));
    $('#question_id').val(link.data('questionid'));
    $('#consumername').html(link.data('consumername'));
    $('#question').html(link.data('question'));
  });
  
    if ($('#units').length > 0 && $('#price').length > 0) {
      var totalCost = $('#units').val() * $('#price').val();
      $('#totalCost').css('color', 'green');
      $('#totalCost').html('Total price: USD '+totalCost);
    }

    $("#units").on('keyup change', function() {
        var totalCost = $('#units').val() * $('#price').val();
        $('#totalCost').css('color', 'green');
        if(totalCost > 999.99) {
          $('#totalCost').css('color', 'red');
          $('#totalCost').html('Total price: USD '+totalCost + ' (cannot exceed 999.99)');
          $(":submit").attr("disabled", true);
        }
        else{
          $('#totalCost').html('Total price: USD '+totalCost);
          $(":submit").attr("disabled", false);
        }
    });

    $("#price").keyup(function(){
        var totalCost = $('#units').val() * $('#price').val();
        $('#totalCost').css('color', 'green');
        if(totalCost > 999.99) {
          $('#totalCost').css('color', 'red');
          $('#totalCost').html('Total price: USD '+totalCost + ' (cannot exceed 999.99)');
          $(":submit").attr("disabled", true);
        }
        else{
          $('#totalCost').html('Total price: USD '+totalCost);
          $(":submit").attr("disabled", false);
        }
    });

  $('#openOffer').click(function() {
    var serviceOption = $('input[name="serviceOptions"]:checked').val();
    var offerStartDate = $('input[name="offerStartDateTime"]').val();
    var offerEndDate = $('input[name="offerEndDateTime"]').val();
    var serviceTitle = $('#serviceOption'+serviceOption).data('title');
    var serviceCost = $('#serviceOption'+serviceOption).data('cost');
    var serviceCostPer = $('#serviceOption'+serviceOption).data('cost-per');
    var taskCost = $('#taskBudget').data('cost');
    var taskCostPer = $('#taskBudget').data('cost-per');
    var units = $('#taskBudget').data('units');

    $('#serviceTitle').html(serviceTitle);
    if(taskCost==null) taskCost='N/A';
    $('#taskCost').html(taskCost);
    $('#taskCostPer').html(taskCostPer);
    $('#price').val(taskCost);
    $('#service_id').val(serviceOption);
    $('#offerUnits').val(units);
    $('#offerCostPer').val(taskCostPer);
    $('#offerCostPerDisplay').html(taskCostPer);
    $('#offerStartDateDisplay').html(offerStartDate);

    var totalCost = $('#units').val() * $('#price').val();
    $('#totalCost').css('color', 'green');
    if(totalCost > 999.99) {
      $('#totalCost').css('color', 'red');
      $('#totalCost').html('Total price: USD '+totalCost + ' (cannot exceed 999.99)');
      $(":submit").attr("disabled", true);
    }
    else{
      $('#totalCost').html('Total price: USD '+totalCost);
      $(":submit").attr("disabled", false);
    }

    var offerEndDateDisplay = '';
    if (offerEndDate) {
      $('#offerEndDateDisplay').html('to ' + offerEndDate);
    }

    $("input[id=offerStartDateTime]").val(offerStartDate)
    $("input[id=offerEndDateTime]").val(offerEndDate)
    if(offerStartDate=='') {
      $('#service-warning').html('Please select a start date');
    }

    if(!serviceOption) {
      $('#service-warning').text('Please select a service first')
    }

    if (serviceOption && offerStartDate) {
      $('#service-warning').hide();
      $('#offerModal').modal('show');
    } else {
      $('#service-warning').show();
    }

  });

  $(document).on('submit', '#offerForm', function (e) {
    e.preventDefault();
    var formData = $(this).serializeArray();
    $.ajax({
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      url: '/offers/sendOffer',
      data: formData,
      success: () => window.location.reload(),
      error: (response) => {
        if(response.status === 422) {
          let error = response.responseJSON.error;
          $('#error').html(error)
        }
      }
      });
  });

  $(document).on('show.bs.modal', '#offerAcceptModal', function (e) {
    var link = $(e.relatedTarget);
    $('#offerIdAccept').val(link.data('id'));
    $('#price').text(link.data('price'));
    $('#offerStartDateTime').val(link.data('start-time'));
    $('#offerEndDateTime').val(link.data('end-time'));
    $('#offerStartDateDisplay').html(link.data('start-time'));
    $('#offerUnits').html(link.data('units'));
    $('#units').val(link.data('units'));
    $('#totalPriceDisplay').html(link.data('totalprice'));
    $('#totalPrice').val(link.data('totalprice'));
    if (link.data('end-time')) {
      $('#offerEndDateDisplay').html('to ' + link.data('end-time'));
    }
  });

  $(document).on('submit', '#offerAcceptForm', function (e) {
    e.preventDefault();
    var formData = $(this).serializeArray();
    $.ajax({
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      url: '/offers/checkout',
      data: formData,
        //success: () => window.location.reload(),
    });
  });

  $(document).on('show.bs.modal', '#offerCancelModal', function (e) {
    var link = $(e.relatedTarget);
      $("input[id=offerId]").val(link.data('id'))
  });

  $(document).on('submit', '#offerDeclineForm', function (e) {
    e.preventDefault();
    var formData = $(this).serializeArray();
    $.ajax({
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      url: '/offers/declineOffer',
      data: formData,
      success: () => window.location.reload(),
    });
  });

  $(document).on('submit', '#offerCancelForm', function (e) {
    e.preventDefault();
    var formData = $(this).serializeArray();
    $.ajax({
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      url: '/offers/cancelOffer',
      data: formData,
      success: () => window.location.reload(),
    });
  });

  $(document).on('submit', '#workcompletedform', function (e) {
    e.preventDefault();
    var formData = $(this).serializeArray();
    $.ajax({
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      url: '/orders/status',
      data: formData,
      success: () => {
        window.location.assign('/orders/list')
      },
    });
  });

  $(document).on('submit', '#workconfirmedform', function (e) {
    e.preventDefault();
    var formData = $(this).serializeArray();
    $.ajax({
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      url: '/orders/status',
      data: formData,
      success: () => {
        window.location.assign('/orders/list')
      },
      error: (response) => {
        if(response.status === 422) {
          let error = response.responseJSON.error;
          $('#paymentError').addClass('is-invalid');
          $('#paymentError').text(error);
        }
      }
    });
  });

  $(document).on('click', '.reset-read', function (e) {
    var link = $(this);
    var id = link.data('id');
    $.ajax({
      type: 'GET',
      url: '/users/readSingle/'+id,
      success: () => {
        document.location.href = '/notifications';
      },
      error: (response) => {
        window.location.reload();
      }
      });
  });

  $('input[id="deleteall"]').click(function () {
      $('#deleteerror').text('');
  });

  $(document).on('submit', '#markreadform', function (e) {
    e.preventDefault();
    var formData = $(this).serializeArray();
    $.ajax({
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      url: '/users/readAll',
      data: formData,
      success: () => window.location.reload(),
    });
  });

  $(document).on('submit', '#markdeleteform', function (e) {
    e.preventDefault();
    var link = $(this);
    if ($('#deleteall').prop("checked") == true) {
      var result = confirm("Delete all read messages?");
      if (result) {
        $.ajax({
          type: 'GET',
          url: '/users/deleteRead/',
          success: () => {
            document.location.href = '/notifications';
          },
          error: (response) => {
            window.location.reload();
          }
        });
      }
    }
    else{
      $('#deleteerror').text('Please check the box')
    }
  });

  $("#description").keyup(function(){
    var el = $(this);
    if(el.val().length <= 100){
        //el.val( el.val().substr(0, 100) );
        $("#charNum").css('color', 'orange');
        $("#minText").css('color', '#6c757d');
        var minText = '(Minimum: 100 characters)';
        $("#minText").show();
        $("#minText").text(minText)
        $("#charNum").text(100-el.val().length + ' more ');
    }
    else if (el.val().length > 100){
        $("#minText").hide();
        $("#charNum").css('color', 'green');
        $("#charNum").text(4000-el.val().length + ' left');
    }

    if(el.val().length > 3860)
    {
        $("#minText").hide();
        $("#charNum").css('color', 'red');
        $("#charNum").text(4000-el.val().length + ' left');
    }

  });

});
